<template>
  <div id="app">
    <div class="container-inside">
      <div class="Resoure_pic">
        <el-image
          class="picing"
          :src="this.api.LoginURL.concat(this.$store.state.userinfo.img)"
        ></el-image>
        <span class="name">{{ this.$store.state.userinfo.name }}</span>
        <el-button type="primary" @click="down"
          ><i class="el-icon-upload el-icon--right"></i
        ></el-button>
      </div>
      <div class="content">
        <div class="zc">
          <div class="zc_x">
            <img class="score" src="@/assets/icon/downla.png" />
            发布资源<span class="down">{{ download }}</span>
          </div>
          <div class="zc_x">
            <img class="score" src="@/assets/icon/Gold1.png" />
            可用知识币
            <span class="down">{{ this.Money }}</span>
          </div>
        </div>
        <div class="resour">
          <el-menu
            class="el-menu-demo"
            mode="horizontal"
            :default-active="this.$route.path"
            :router="true"
          >
            <el-menu-item index="myresourxce">我的资源</el-menu-item>
            <el-menu-item index="mydownload">点击上传</el-menu-item>
          </el-menu>
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { INSERT, QUERY, UPDATE } from "@/services/dao.js";
export default {
  name: "index",
  data() {
    return {
      transaction: {
        img: require("@/assets/timg (9).png"),
        name: "aaa",
        zhishi: "10",
        download: "200",
      },
      activeName: "first",
      activeIndex: "1",
      Money: "",
      download: "",
    };
  },
  created() {
    this.init();
    this.info();
  },
  methods: {
    async init() {
      let info = await QUERY(
        "post",
        "",
        "  PCUsers(where: {id: {_eq: " +
          this.$store.state.userinfo.id +
          "}}) {     Money     }"
      );
      this.Money = info.data.PCUsers[0].Money;
    },
    async info() {
      let res = await QUERY(
        "post",
        "",
        'ZYDocument( where: {user: {_eq: "' +
          this.$store.state.userinfo.id +
          '"}},limit: ' +
          10 +
          ", offset: " +
          0 +
          ") { id     }"
      );
      this.download = res.data.ZYDocument.length;
    },
    down() {
      this.$router.push({ name: "mydownload" });
    },
  },
};
</script>

<style scoped>
.Resoure_pic {
  width: 173px;
  background-color: #ffffff;
  /* overflow: hidden; */
  float: left;
  text-align: center;
  margin-left: 50px;
  border: 1px solid rgba(210, 210, 210, 1);
}
.picing {
  width: 110px;
  height: 110px;
  border-radius: 999px;
  margin: 33px 33px 0px 33px;
}
.name {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;

  line-height: 50px;
  width: 100%;
}
.Resoure_pic button {
  width: 100%;
}
.Resoure_list {
  float: left;
  width: 765px;
  background-color: #ffffff;
  margin-left: 2px;
}
.content {
  float: left;
  width: 800px;
  background-color: #ffffff;
}
.el-menu-item {
  margin-left: 130px;
  margin-right: 130px;
}
.el-menu-demo {
  border: 1px solid #f7f7f7;
}
.resour {
  margin: 10px;
  border: 1px solid rgba(210, 210, 210, 1);
}
.zc {
  width: 100%;
  overflow: hidden;
}
.zc img {
  width: 14px;
}
.zc_x {
  float: right;
  margin: 5px 30px 0px 10px;
}
.el-form {
  margin: 30px;
}
.container-inside {
  width: 80%;
  margin: 0 auto;
}
.container-inside::after {
  display: block;
  content: "";
  clear: both;
}
</style>
